export const defaultIdValue = '7e3d75d6-9b75-4b23-8829-f42d317945f4'
export const defaultTemplate = '186c351b-74a7-4ea0-acb0-c7ef3831d9e4'

export const templateColors = [
    { value: 'dark', label: 'Dark' },
    { value: 'blue', label: 'Blue' },
    { value: 'white', label: 'White' },
    { value: 'purple', label: 'Purple' },
    { value: 'black', label: 'Black' },
    { value: 'green', label: 'Green' }
]

export const statusOptions1 = [
    { value: '', label: 'All' },
    { value: 'true', label: 'Allowed' },
    { value: 'false', label: 'Blocked' }
]

export const currencyTypeData = [
    { value: 'fiat', label: 'Fiat' },
    { value: 'crypto', label: 'Crypto' }
]

export const defaultItem = { value: '', label: 'Please Select' }

export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

export const statusOptions = [
    { value: 'allow', label: 'Allowed' },
    { value: 'pending', label: 'Pending' },
    { value: 'block', label: 'Blocked' }
]

export const statusObj = {
    pending: 'light-warning',
    allow: 'light-success',
    block: 'light-danger'
}

export const pagenationOptions = [10, 25, 50, 100]

export const SuperAgentUser = 'e7fea3f7-2374-46eb-b40a-3f149b220f77'
export const PlayerPermission = '466bb72d-e0fc-4d19-aec9-3dcd76967f21'
export const SuperAgentUserPermission = 'ab420b7d-3803-48bd-afe0-8d2259dabd4d'

export const paymentType = [
    { value: '', label: 'Please Select' },
    { value: 'deposit', label: 'Deposit' },
    { value: 'withdraw', label: 'Withdraw' }
]

export const gameOptions = [
    { value: '', label: 'All' },
    { value: 'isnew', label: 'New Game' },
    { value: 'desktop', label: 'Desktop Game' },
    { value: 'mobile', label: 'Mobile Game' }
]

export const userType = [
    { value: '', label: 'All' },
    { value: 'agent', label: 'AGENT' },
    { value: 'player', label: 'PLAYER' }
]

export const payDecision = [
    { value: '', label: 'Please Select' },
    { value: 'allow', label: 'allowed' },
    { value: 'block', label: 'blocked' }
]

export const payPendingDecision = [
    { value: '', label: 'Please Select' },
    { value: 'approve', label: 'approve' },
    { value: 'reject', label: 'reject' }
]

export const deviceList = [
    { label: 'Desktop', value: 'desktop' },
    { label: 'Mobile', value: 'mobile' }
]

export const dummyData = {
    data: [],
    totalPages: 0,
    totalCount: 0,
    totalDWData: []
}

export const BetType = [
    { value: '', label: 'All' },
    { value: 'bet', label: 'Bet' },
    { value: 'win', label: 'Win' }
]

export const paymentFilterType = [
    { value: '', label: 'All' },
    { value: 'deposit', label: 'Deposit' },
    { value: 'withdraw', label: 'Withdraw' }
]

export const paymentStatus = [
    { value: '', label: 'All' },
    { value: 'success', label: 'Success' },
    { value: 'pending', label: 'Pending' },
    { value: 'failure', label: 'Failure' },
    { value: 'waiting_confirm', label: 'Waiting' }
]
