import APIAbstract from "./APIAbstract"
import { Root } from "../configs/serverConfig"

class CMSApi extends APIAbstract {
  // Site Config
  getAllTempAndDomains() {
    return this.post("/admin/cms/siteconfig/getAllTempAndDomains")
  }
  submitSiteConfig(data) {
    return this.post("/admin/cms/siteconfig/submitSiteConfig", data)
  }
  uploadMultiLang(data) {
    return this.post("/admin/cms/siteconfig/uploadMultiLang", data)
  }
  getSiteConfigDetail(data) {
    return this.post("/admin/cms/siteconfig/getSiteConfigDetail", data)
  }
  getAdminConfig(data) {
    return this.post("/admin/cms/siteconfig/getAdminConfig", data)
  }
  //  ------------------------------------------------------------------

  // Slider
  getAllSlider(data = {}) {
    return this.post("/admin/cms/slider/getAllSlider", data)
  }
  submitSlider(data) {
    return this.post("/admin/cms/slider/submitSlider", data)
  }
  submitSliderOrder(data) {
    return this.post("/admin/cms/slider/submitSliderOrder", data)
  }
  deleteMainSlider(data = {}) {
    return this.post("/admin/cms/slider/deleteMainSlider", data)
  }

  //  ------------------------------------------------------------------

  // Sidebar Main Menu
  getAllSidebarList(data) {
    return this.post("/admin/cms/sidebar/getAllSidebarList", data)
  }
  getAllSidebar(data) {
    return this.post("/admin/cms/sidebar/getAllSidebar", data)
  }
  submitSidebarItem(data) {
    return this.post("/admin/cms/sidebar/submitSidebarItem", data)
  }
  submitSidebarOrder(data) {
    return this.post("/admin/cms/sidebar/submitSidebarOrder", data)
  }
  //  ------------------------------------------------------------------

  // Footer Menu Manage
  getAllFooterMenu(data) {
    return this.post("/admin/cms/footer/getAllFooterMenu", data)
  }
  submitFooterMenu(data) {
    return this.post("/admin/cms/footer/submitFooterMenu", data)
  }
  submitFooterMenuOrder(data) {
    return this.post("/admin/cms/footer/submitFooterMenuOrder", data)
  }

}
export default new CMSApi(`${Root.serverUrl}`)
