import APIAbstract from './APIAbstract'
import { Root } from '../configs/serverConfig'

class ConfigApi extends APIAbstract {
    /**
     * Config Country List
     */
    getCountry(data) {
        return this.post('/admin/config/country/getCountry', data)
    }
    getCountryList(data) {
        return this.post('/admin/config/country/getCountryList', data)
    }
    submitCountry(data) {
        return this.post('/admin/config/country/submitCountry', data)
    }

    //  ------------------------------------------------------------------

    /**
     * Config Currency List
     */
    getAllCurrency(data) {
        return this.post('/admin/config/currency/getAllCurrency', data)
    }
    getCurrencyList(data) {
        return this.post('/admin/config/currency/getCurrencyList', data)
    }
    submitCurrency(data) {
        return this.post('/admin/config/currency/submitCurrency', data)
    }
    getExportAllCurrency(data) {
        return this.post('/admin/config/currency/getExportAllCurrency', data)
    }

    //  ------------------------------------------------------------------

    /**
     * Config Language
     */
    getLanguage(data) {
        return this.post('/admin/config/language/getLanguage', data)
    }
    getAllLanguage(data) {
        return this.post('/admin/config/language/getAllLanguage', data)
    }
    submitLanguage(data) {
        return this.post('/admin/config/language/submitLanguage', data)
    }
    getExportAllLanguage(data) {
        return this.post('/admin/config/language/getExportAllLanguage', data)
    }

    getMultilang() {
        return this.post('/admin/config/language/getMultilangData')
    }
    //  ------------------------------------------------------------------

    /**
     * Config Site
     */
    getAllSites(data) {
        return this.post('/admin/config/site/getAllSites', data)
    }
    getAllSiteList(data) {
        return this.post('/admin/config/site/getAllSiteList', data)
    }
    submitSite(data) {
        return this.post('/admin/config/site/submitSite', data)
    }
    getExportAllSiteTemplate(data) {
        return this.post('/admin/config/site/getExportAllSiteTemplate', data)
    }
    //  ------------------------------------------------------------------

    /**
     * Config Domain
     */
    getAllDomain(data) {
        return this.post('/admin/config/domain/getAllDomain', data)
    }
    submitDomain(data) {
        return this.post('/admin/config/domain/submitDomain', data)
    }
    getExportAllDomains(data) {
        return this.post('/admin/config/domain/getExportAllDomains', data)
    }

}
export default new ConfigApi(`${Root.serverUrl}`)
