import APIAbstract from './APIAbstract'
import { Root } from '../configs/serverConfig'

class SiteApi extends APIAbstract {
    /**
     * Permission Menu
     */
    getAllAdminLeftSidebar(data) {
        return this.post('/admin/permission/menu/getAllAdminLeftSidebar', data)
    }
    submitSidebarItem(data) {
        return this.post('/admin/permission/menu/submitSidebarItem', data)
    }
    updateItemPosition(data) {
        return this.post('/admin/permission/menu/updateItemPosition', data)
    }
    deletemenuItem(data) {
        return this.post('/admin/permission/menu/deletemenuItem', data)
    }
    //  ------------------------------------------------------------------

    /**
     * Permission Function List
     */
    getAllFunctions(data) {
        return this.post('/admin/permission/function/getAllFunctions', data)
    }
    submitFunction(data) {
        return this.post('/admin/permission/function/submitFunction', data)
    }
    deleteFunction(data) {
        return this.post('/admin/permission/function/deleteFunction', data)
    }
}
export default new SiteApi(`${Root.serverUrl}`)
