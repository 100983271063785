import create from 'zustand'
import { toast } from 'react-toastify'
import { SuccessToast } from '@src/views/ui-elements/ToastContents'
import ToastConfig from '@src/configs/toastConfig'
import { Root } from '@configs/serverConfig'
import { dummyData } from '@configs/dataConfig'
import ConfigApi from '../api/ConfigApi'

const useStore = create((set) => ({
    multilang: [],
    /**
     * Config Country List
     */
    getCountry: async (data) => {
        Root.show()
        const res = await ConfigApi.getCountry(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return dummyData
        }
    },
    submitCountry: async (data) => {
        Root.show()
        const res = await ConfigApi.submitCountry(data)
        Root.hide()
        if (res) {
            toast.success(<SuccessToast />, ToastConfig)
            return true
        } else {
            return false
        }
    },
    //  ------------------------------------------------------------------

    /**
     * Config Currency List
     */
    getAllCurrency: async (data) => {
        Root.show()
        const rdata = await ConfigApi.getAllCurrency(data)
        Root.hide()
        if (rdata) {
            return rdata.data.data
        } else {
            return dummyData
        }
    },
    getCurrencyList: async (data) => {
        Root.show()
        const rdata = await ConfigApi.getCurrencyList(data)
        Root.hide()
        if (rdata) {
            return rdata.data.data
        } else {
            return []
        }
    },
    submitCurrency: async (data) => {
        Root.show()
        const res = await ConfigApi.submitCurrency(data)
        Root.hide()
        if (res) {
            return true
        } else {
            return false
        }
    },
    getExportAllCurrency: async (data) => {
        Root.show()
        const res = await ConfigApi.getExportAllCurrency(data)
        Root.hide()
        if (res) {
            return res.data
        } else {
            return false
        }
    },

    //  ------------------------------------------------------------------

    /**
     * Config Language
     */
    getLanguage: async (data) => {
        Root.show()
        const res = await ConfigApi.getLanguage(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return dummyData
        }
    },
    getAllLanguage: async (data) => {
        Root.show()
        const res = await ConfigApi.getAllLanguage(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return []
        }
    },
    submitLanguage: async (data) => {
        Root.show()
        const flag = await ConfigApi.submitLanguage(data)
        Root.hide()
        if (flag) {
            toast.success(<SuccessToast />, ToastConfig)
            return true
        } else {
            return false
        }
    },
    getExportAllLanguage: async (data) => {
        Root.show()
        const res = await ConfigApi.getExportAllLanguage(data)
        Root.hide()
        if (res) {
            return res.data
        } else {
            return false
        }
    },
    getMultilang: async () => {
        const rdata = await ConfigApi.getMultilang()
        if (rdata) {
            set({ multilang: rdata.data.data })
            return rdata.data
        } else {
            return []
        }
    },

    //  ------------------------------------------------------------------

    /**
     * Config Site
     */
    getAllSites: async (data) => {
        Root.show()
        const res = await ConfigApi.getAllSites(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return dummyData
        }
    },
    getAllSiteList: async (data) => {
        Root.show()
        const res = await ConfigApi.getAllSiteList(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return dummyData
        }
    },
    submitSite: async (data) => {
        Root.show()
        const flag = await ConfigApi.submitSite(data)
        Root.hide()
        if (flag) {
            toast.success(<SuccessToast />, ToastConfig)
            return true
        } else {
            return false
        }
    },
    getExportAllSiteTemplate: async (data) => {
        Root.show()
        const res = await ConfigApi.getExportAllSiteTemplate(data)
        Root.hide()
        if (res) {
            return res.data
        } else {
            return false
        }
    },

    //  ------------------------------------------------------------------

    /**
     * Config Domain
     */
    getAllDomain: async (data) => {
        Root.show()
        const res = await ConfigApi.getAllDomain(data)
        Root.hide()
        if (res) {
            return res.data.data
        } else {
            return dummyData
        }
    },
    submitDomain: async (data) => {
        Root.show()
        const flag = await ConfigApi.submitDomain(data)
        Root.hide()
        if (flag) {
            toast.success(<SuccessToast />, ToastConfig)
            return true
        } else {
            return false
        }
    },
    getExportAllDomains: async (data) => {
        Root.show()
        const res = await ConfigApi.getExportAllDomains(data)
        Root.hide()
        if (res) {
            return res.data
        } else {
            return false
        }
    }

}))

export default useStore
