import APIAbstract from "./APIAbstract"
import { Root } from "../configs/serverConfig"

class AuthApi extends APIAbstract {

  userLogin(data = {}) {
    return this.post("/admin/auth/signin", data)
  }
  sessionCheck(data = {}) {
    return this.get("/admin/auth", data)
  }
}
export default new AuthApi(`${Root.serverUrl}`)
