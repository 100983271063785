import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { LoadingProvider } from './utility/context/loadingContext'
import AuthProvider from './utility/context/auth'
import Contextloading from './views/ui-elements/loading/index'
import Spinner from './@core/components/spinner/Fallback-spinner'

import '@styles/react/libs/toastify/toastify.scss'
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import '@styles/react/apps/app-users.scss'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css'
import 'react-toggle/style.css'
import 'animate.css/animate.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import 'react-image-crop/dist/ReactCrop.css'
import 'jsoneditor-react/es/editor.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import "rc-tooltip/assets/bootstrap.css"
import 'react-phone-input-2/lib/style.css'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

import './configs/i18n'

import * as serviceWorker from './serviceWorker'

const Router = lazy(() => import('./Router'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LoadingProvider>
        <ThemeContext>
          <Contextloading />
          <AuthProvider>
            <Router />
          </AuthProvider>
          <ToastContainer newestOnTop />
        </ThemeContext>
      </LoadingProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()

