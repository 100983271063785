import { defaultIdValue } from '@src/configs/dataConfig'

export const listToTree = (list) => {
    const map = {}
    const roots = []
    let node

    for (let i = 0; i < list.length; i++) {
        map[list[i].id] = i
    }

    for (let i = 0; i < list.length; i++) {
        node = list[i]
        if (node.pid !== defaultIdValue) {
            if (list[map[node.pid]]) {
                if (list[map[node.pid]].children) {
                    list[map[node.pid]].children.push(node)
                } else {
                    list[map[node.pid]].children = [node]
                }
            }
        } else {
            roots.push(node)
        }
    }
    return roots
}

export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const toFixed = (num, fixed) => {
    fixed = fixed || 0
    fixed = Math.pow(10, fixed)
    return Math.floor(num * fixed) / fixed
}
