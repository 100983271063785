import axios from 'axios'
import { toast } from 'react-toastify'
import { ApiErrorToast } from '@src/views/ui-elements/ToastContents'
import ToastConfig from '@src/configs/toastConfig'
import { Root } from '../configs/serverConfig'

class APIAbstract {
    constructor(apiEndpoint) {
        axios.interceptors.request.use(
            (config) => {
                config.baseURL = apiEndpoint
                config.headers = { authorization: this.getToken() }
                return config
            },
            (error) => Promise.reject(error)
        )
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const { response } = error
                if (!response) {
                    toast.error(
                        <ApiErrorToast title="Network Error" />,
                        ToastConfig
                    )
                    return
                }
                if (response.status === 401) {
                    toast.error(
                        <ApiErrorToast title={response.data.message} />,
                        ToastConfig
                    )
                    this.clearToken()
                    setTimeout(() => {
                        window.location.reload()
                    }, 100)
                    return
                }
                if (response.status === 404) {
                    toast.error(
                        <ApiErrorToast title={'Not found endpoint'} />,
                        ToastConfig
                    )
                } else if (response.status === 405) {
                    window.location.assign('/blocked')
                } else if (
                    response.status === 400 ||
                    response.status === 403 ||
                    response.status === 500 ||
                    response.status === 429 ||
                    response.status === 413 ||
                    response.status === 500
                ) {
                    toast.error(
                        <ApiErrorToast
                            title={response.data.message || response.data}
                        />,
                        ToastConfig
                    )
                } else {
                    console.log(response)
                }
                return false
            }
        )
    }

    objectToQueryString(obj) {
        const str = []
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(
                    `${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`
                )
            }
        }
        return str.join('&')
    }

    async get(url, data = {}) {
        return axios.get(`${url}?${this.objectToQueryString(data)}`)
    }

    async post(url, data = {}) {
        return axios.post(`${url}`, data)
    }

    async put(url, data = {}) {
        return axios.put(`${url}`, data)
    }

    async delete(url, data = {}) {
        return axios.delete(`${url}?${this.objectToQueryString(data)}`)
    }

    getToken() {
        const data = window.localStorage.getItem(Root.token)
        return data ? data : false
    }

    setToken(token) {
        window.localStorage.setItem(Root.token, token)
    }

    clearToken() {
        window.localStorage.removeItem(Root.token)
    }
}

export default APIAbstract
