const origin = window.location.origin.replace('http://', '').replace('https://', '').replace('admin.', '')
const site = origin.includes('localhost') ? 'onlinecasinolucky.com' : origin

export const Root = process.env.NODE_ENV === "development" ? {
    serverUrl: "http://localhost:3000",
    siteUrl: "http://localhost:3001",
    token: `admin-${site}-token`,

    site,
    show: null,
    hide: null
} : {
    serverUrl: `https://server.${site}`,
    siteUrl: `https://admin.${site}`,
    token: `admin-${site}-token`,

    site,
    show: null,
    hide: null
}
