import create from 'zustand'
import AuthApi from '../api/AuthApi'
import CMSApi from '../api/CMSApi'
import PermissionApi from '../api/PermissionApi'
import { toast } from 'react-toastify'
import { LoginToastContent } from '@src/views/ui-elements/ToastContents'
import ToastConfig from '@src/configs/toastConfig'
import { Root } from '../configs/serverConfig'
import { listToTree, toFixed } from '../utility/Utils'

const useStore = create((set) => ({
    Auth: {
        isAuth: false,
        isChecked: false,
        user: {},
        balance: 0
    },
    sidebar: [],
    currencies: [],
    logoImage: '',
    userLogin: async (data) => {
        Root.show()
        const res = await AuthApi.userLogin(data)
        Root.hide()
        if (res && res.data) {
            const { accessToken, user, functionPermission } = res.data.data
            AuthApi.setToken(accessToken)
            toast.success(
                <LoginToastContent name={user.username} />,
                ToastConfig
            )
            setTimeout(() => {
                const firstRedirect = functionPermission.find(item => item.function === 'firstredirectplayer')
                if (firstRedirect && firstRedirect.status) {
                    window.location.assign('/player/all')
                } else {
                    window.location.assign('/dashboard')
                }
            }, 500)
        }
    },
    sessionCheck: async () => {
        const init = async () => {
            Root.show()
            const configData = await CMSApi.getAdminConfig({ site: Root.site === 'onlinecasinolucky.com' ? 'admin.onlinecasinolucky.com' : `${Root.site}` })
            Root.hide()
            if (configData && configData.data.data) {
                const logoData = configData.data.data.logo || ''
                const faviconData = configData.data.data.favicon || ''
                const adminNameData = configData.data.data.sitename || ''

                document.title = adminNameData
                const link = document.querySelector("link[rel~='icon']")
                if (!link) {
                    link = document.createElement('link')
                    link.rel = 'icon'
                    document.getElementsByTagName('head')[0].appendChild(link)
                }
                if (logoData) {
                    set({ logoImage: `${Root.serverUrl}${logoData}` })
                } else {
                    set({
                        logoImage: `${Root.siteUrl}/image/site/logo/logo.png`
                    })
                }
                if (faviconData) {
                    link.href = `${Root.serverUrl}${faviconData}`
                } else {
                    link.href = `${Root.siteUrl}/image/site/logo/favicon.ico`
                }
            }
        }
        init()

        if (AuthApi.getToken()) {
            Root.show()
            const sideData = await PermissionApi.getAllAdminLeftSidebar()
            const res = await AuthApi.sessionCheck()
            Root.hide()
            if (res) {
                set({
                    Auth: {
                        isAuth: true,
                        user: res.data.data.user,
                        balance: toFixed(res.data.data.balance, 2),
                        isChecked: true
                    },
                    sidebar: listToTree(sideData.data.data)
                    // currencies: res.data.currencies,
                })
            } else {
                AuthApi.clearToken()
                setTimeout(() => {
                    window.location.reload()
                }, 100)
            }
        } else {
            set({ Auth: { isAuth: false, user: {}, isChecked: true } })
        }
    },
    sessionUpdate: async () => {
        const res = await AuthApi.sessionCheck()
        if (res) {
            set({
                Auth: {
                    isAuth: true,
                    user: res.data.data.user,
                    balance: toFixed(res.data.data.balance, 2),
                    isChecked: true
                }
            })
        }
    },
    sendLogout: async () => {
        AuthApi.clearToken()
        window.location.assign('/login')
    }
}))

export default useStore
